<template>
    <div class="igo_fundraising">
        <Popup/>
        <LeftMenu/>
        <Top/>
        <div class="fundraising_content">
            <div class="fundraising_title">

                <img src = "../../../assets/images/pc/weixing_1.png" />

                <div class="title_text">
                    <div class="text_top">
                        Welcome to our Gamified Launchpad
                        in CheersLand!
                    </div>
                    <div class="text_bottom">
                        Fasten your seatbelts and join the journey of Initial Gaming
                        Offering (IGO) of startups to get new tokens!
                    </div>
                </div>

                <div class="title_banner">
                    <el-carousel trigger="click" height="150px">
                        <el-carousel-item>
                            <img src="../../../assets/images/photo_1.jpg"/>
                        </el-carousel-item>
                        <el-carousel-item>
                            <img src="../../../assets/images/photo_2.jpg"/>
                        </el-carousel-item>
                        <el-carousel-item>
                            <img src="../../../assets/images/photo_3.jpg"/>
                        </el-carousel-item>
                    </el-carousel>
                </div>

            </div>

            <div class="fundraising_control">

                <div :class="igoOption === 'left' ? 'igo_option' : 'igo_option igo_option_upd'">
                    <div v-if="igoOption === 'left'" @click="setIgoOption('left')" class="live"> Recent IGO </div>
                    <div v-if="igoOption !== 'left'" class="upd_live"> <div @click="setIgoOption('left')" class="text_live"> Recent IGO </div> </div>
                    <div v-if="igoOption === 'left'" class="finished"> <div @click="setIgoOption('right')" class="text_finished"> Past IGOs </div> </div>
                    <div v-if="igoOption !== 'left'" @click="setIgoOption('right')" class="upd_finished"> Past IGOs </div>
                </div>

                <div class="igo_search">
                    <img src="../../../assets/images/pc/sou.png"/>
                    <input placeholder=" Search Token" class="search_input"/>
                </div>

            </div>

            <div class="fundraising_pool">

                <div v-show="igoOption === 'left' ? !pool.isEnd : pool.isEnd" class="pool_info" v-for="(pool, index) in fundraisingPools" :key="index">
                    <div class="top_label">
                        {{pool.poolAddress !== '0x000' ? (pool.isEnd ? 'CLOSED' : (pool.isStart ? 'OPEN' : 'IN ' + pool.poolRemainingTime + ' DAYS')) : 'On the 28th'}}
                    </div>
                    <div class="pool_top">
                        <div class="tu_biao">
                            <img :src="pool.earnImg" />
                        </div>
                        <div class="pool_link">
                            <div class="link_text">{{pool.poolName}}</div>
                            <div class="to_link">
                                <a :href="$store.state.aboutProject[pool.poolName].linka" target="_blank">
                                    <img src="../../../assets/images/pc/lianjie_1.png" />
                                </a>
                                <a :href="$store.state.aboutProject[pool.poolName].linkb" target="_blank">
                                    <img src="../../../assets/images/pc/tel_2.png" />
                                </a>
                                <a :href="$store.state.aboutProject[pool.poolName].linkc" target="_blank">
                                    <img src="../../../assets/images/pc/tw_1.png" />
                                </a>
                                <a :href="$store.state.aboutProject[pool.poolName].linkd" target="_blank">
                                    <img src="../../../assets/images/pc/mon_1.png" />
                                </a>
                            </div>
                        </div>
                        <div class="pool_name">{{pool.earn}}/{{pool.stake}}</div>
                    </div>

                    <div class="pool_data">

                        <img src="../../../assets/images/pc/huojian2.png"/>

                        <div class="pool_remarks">{{pool.remarks}}</div>

                        <div class="data_info">
                            <div :class="pool.isLeft1 ? 'left_data many' : 'left_data'">
                                <div v-show="pool.isShow1">Sale Price:</div>
                                <div>{{pool.isLeft2 ? 'Total Committed:' : 'IGO Starts:'}}</div>
                                <div>{{pool.isLeft3 ? 'IGO Ends:' : ''}}</div>
                                <div v-show="pool.isShow5">{{pool.isLeft4 ? 'Hard Cap:' : 'Claim Starts:'}}</div>
                            </div>

                            <div :class="pool.isLeft1 ? 'right_data many' : 'left_data'">
                                <div v-show="pool.isShow1">1 {{pool.stake}} = {{pool.price}} {{pool.earn}}</div>
                                <div v-show="pool.isShow2">{{ setDecimal(pool.realization, 6, false, false) }} {{pool.stake}} ({{ setDecimal(pool.ratio, 3, false, false) }}%)</div>
                                <div v-show="pool.isShow2">{{pool.hardCap}} {{pool.stake}}</div>

                                <div v-show="pool.isShow3">{{pool.igoStarts}}</div>
                                <div v-show="pool.isShow4">{{pool.igoEnds}}</div>
                                <div v-show="pool.isShow5">{{pool.claimStarts}}</div>
                            </div>
                        </div>

                        <div class="pool_button" @click="toPoolInfo(
                            pool.poolIndex,
                            pool.poolName,
                            pool.igoType,
                            pool.poolAddress,
                            pool.stake,
                            pool.tokenAddress,
                            pool.price,
                            pool.hardCap,
                            pool.earnTokenAddress,
                            pool.earn,
                            pool.earnImg,
                            pool.isPoolRefund,
                            pool.isLink,
                            pool.introduce
                        )" v-if="pool.poolAddress !== '0x000'">
                            {{(pool.isStart && !pool.isEnd) ? 'Join IGO' : 'View Details'}}
                        </div>

                        <div class="pool_button" v-if="pool.poolAddress === '0x000'">
                            Upcoming
                        </div>

                    </div>
                </div>
            </div>

            <div class="node_info">

                <div class="img1">1</div>
                <div class="img2">2</div>
                <div class="img3">3</div>
                <div class="img4">4</div>
                <div class="juxing"/>

                <div class="div1">
                    How To Take Part
                </div>

                <div class="div2">
                    <div class="div_title">Get Whitelisted</div>
                    <div class="div_info">
                        Play the game to get yourself on Ranking List, or join the Farms and stake the required amount of
                        tokens before the IGO.
                    </div>
                    <div class="div_img">Join the Farms</div>
                </div>

                <div class="div3">
                    <div class="div_title">Prepare funds</div>
                    <div class="div_info">
                        Before the IGO, you need to get your fund prepared in your wallet, such as USDT, BUSD, BNB, CHEERS,
                        etc..
                    </div>
                    <div class="div_img"></div>
                </div>

                <div class="div4">
                    <div class="div_title">Commit funds</div>
                    <div class="div_info">
                        While the IGO sale is live, you can 'commit' your funds to buy IGO tokens.
                    </div>
                    <div class="div_img"></div>
                </div>

                <div class="div5">
                    <div class="div_title">Claim your tokens</div>
                    <div class="div_info">
                        After the IGO, you can claim IGO tokens that you bought according to the proportion and the
                        unspent funds.
                    </div>
                    <div class="div_img"></div>
                </div>

                <div class="div6">
                    <div class="tisp">
                        Tips:
                    </div>
                    <div class="tisp_info">
                        1. IGO usually lasts one hour and everyone can participate without any fee.<br/>
                        2. Remember to add the Official Contract Address of IGO tokens into your wallet.
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Popup from "../../../components/popup/Index";
    import LeftMenu from "../../../components/frames/LeftMenu";
    import Top from "../../../components/frames/Top";
    import {mixin1} from "../../../tools/mixins";
    import * as metaMaskTools from "../../../tools/chain";
    import store from "../../../store";
    import toolUtils from "../../../tools/toolUtils";
    import {BigNumberDiv} from "../../../tools";

    export default {
        name: "Index",
        components: {Popup, LeftMenu, Top},
        mixins: [mixin1],
        data() {
            return {
                igoOption: 'left',
                fundraisingPools: [
                    // 正式

                    // 测试
                ],
                accounts: null
            };
        },
        watch: {
        },
        created() {
            setTimeout(() => {
                this.onAccountsChanged();
            }, 1000)
        },
        mounted() {
            this.initFundraisingPools()
        },
        beforeDestroy() {
        },
        methods: {
            onRefresh(accounts) {
                this.$store.commit('setState', {
                    accounts
                });
                localStorage.setItem('accounts', accounts);
                if (localStorage.getItem('accounts')) {
                    this.accounts = localStorage.getItem('accounts')
                }
                this.initFundraisingPools()
            },
            setIgoOption(text) {
                this.igoOption = text
            },
            setDecimal(numVal, numDecimalMax, roundUp, science) {
                return toolUtils.setDecimal(numVal, numDecimalMax, roundUp, science)
            },
            toPoolInfo(poolIndex, poolName, igoType, contract, lpType, lpToken, lpPrice, hardCap, earnToken, earn, earnImg, isPoolRefund, isLink, introduce) {
                sessionStorage.setItem("poolIndex", poolIndex)
                sessionStorage.setItem("poolName", poolName)
                sessionStorage.setItem("igoType", igoType)
                sessionStorage.setItem("poolContract", contract)
                sessionStorage.setItem("lpType", lpType)
                sessionStorage.setItem("lpToken", lpToken)
                sessionStorage.setItem("lpPrice", lpPrice)
                sessionStorage.setItem("hardCap", hardCap)
                sessionStorage.setItem("earnToken", earnToken)
                sessionStorage.setItem("earn", earn)
                sessionStorage.setItem("earnImg", earnImg)
                sessionStorage.setItem("isPoolRefund", isPoolRefund)
                sessionStorage.setItem("isLink", isLink)
                sessionStorage.setItem("introduce", introduce)
                this.$router.push({
                    path: "/pool",
                    query: {
                        igoPoolType: 0,
                        poolIndexNum: poolIndex
                    }
                });
            },
            toFarm() {
                this.$router.push("/mining");
            },
            async isStartFundRaising(contract, index) {
                try {
                    await metaMaskTools.isStartFundRaising(contract).then(async (data) => {
                        this.fundraisingPools[index].isStart = data
                    });
                } catch (e) {
                    console.log(e)
                }
            },
            async isEndFundRaising(contract, index) {
                try {
                    await metaMaskTools.isEndFundRaising(contract).then(async (data) => {
                        this.fundraisingPools[index].isEnd = data
                    });
                } catch (e) {
                    console.log(e)
                }
            },
            async remainingTime(contract, index) {
                try {
                    await metaMaskTools.remainingTime(contract, 0).then(async (data) => {
                        this.fundraisingPools[index].poolRemainingTime = parseInt(BigNumberDiv(data, (60 * 60 * 24)))
                    });
                } catch (e) {
                    console.log(e)
                }
            },
            async getExchangePoolDetails(contract, index) {
                try {
                    await metaMaskTools.getExchangePoolDetails(contract).then(async (data) => {
                        if (contract === '0xB23C1293e17b8E3ba6CD69fA1122f736487C40c5') {
                            this.fundraisingPools[index].price = parseFloat(data[9])
                        } else {
                            this.fundraisingPools[index].price = BigNumberDiv(parseFloat(data[9]), 1e18)
                        }
                        this.fundraisingPools[index].hardCap = BigNumberDiv(parseFloat(data[7]), 1e18)
                        this.fundraisingPools[index].realization = BigNumberDiv(parseFloat(data[10]), 1e18)

                        this.fundraisingPools[index].ratio = BigNumberDiv(parseFloat(this.fundraisingPools[index].realization) * 100, parseFloat(this.fundraisingPools[index].hardCap))
                    });
                } catch (e) {
                    console.log(e)
                }
            },
            initFundraisingPools() {
                for (let i = 0; i < this.fundraisingPools.length; i++) {
                    if (this.fundraisingPools[i].poolAddress !== '0x000') {
                        this.getExchangePoolDetails(this.fundraisingPools[i].poolAddress, i)
                        this.remainingTime(this.fundraisingPools[i].poolAddress, i)
                        this.isStartFundRaising(this.fundraisingPools[i].poolAddress, i)
                        this.isEndFundRaising(this.fundraisingPools[i].poolAddress, i)
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>

<style lang="less">
    .igo_fundraising {
        .connectWallet {
            @media screen and (max-width: 768px) {
                zoom: 2.5;
            }
        }
        .fundraising_content::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
        .fundraising_content {
            @media screen and (max-width: 768px) {
                position: fixed;
                left: 0px;
                top: 145px;
                zoom: 1.5;
            }
            position: absolute;
            bottom: 0;
            top: 120px;
            right: 0;
            left: 260px;
            color: #FFFFFF;
            min-width: 820px;
            min-height: 611px;
            overflow-y: auto;
            background-color: #0e1429;
            background-image: url("../../../assets/images/pc/bg_all.png");
            background-size: 100% auto;
            .fundraising_title {
                display: flex;
                align-items: center;
                justify-content: space-around;
                height: 220px;
                width: 96%;
                margin: 30px 2%;
                border-radius: 12px;
                background-color: #3A3A3A;
                img {
                    position: absolute;
                    left: 80px;
                    height: 220px;
                    width: 300px;
                }
                .title_text {
                    width: calc(100% - 450px);
                    .text_top {
                        font-size: 26px;
                        font-family: Poppins;
                        font-weight: 500;
                        color: #FFFFFF;
                        line-height: 42px;
                    }
                    .text_bottom {
                        @media screen and (max-width: 768px) {
                            font-size: 19px;
                        }

                        font-size: 17px;
                        font-family: Poppins;
                        font-weight: 500;
                        color: #FFFFFF;
                        line-height: 27px;
                    }
                }
                .title_banner {
                    height: 180px !important;
                    width: 380px;
                    text-align: center;
                    img {
                        position: absolute;
                        left: 0;
                        height: 180px;
                        /*width: 420px;*/
                        width: 380px;
                    }
                    .el-carousel--horizontal {
                        height: 180px !important;
                        border-radius: 10px !important;
                    }
                    .el-carousel__container {
                        height: 180px !important;
                    }
                    .el-carousel__item h3 {
                        color: #475669;
                        font-size: 14px;
                        opacity: 0.75;
                        line-height: 150px;
                        margin: 0;
                    }
                    .el-carousel__item:nth-child(2n) {
                        background-color: #99a9bf;
                    }
                    .el-carousel__item:nth-child(2n+1) {
                        background-color: #d3dce6;
                    }
                }
            }
            .fundraising_control {
                @media screen and (max-width: 768px) {
                    zoom: 1.35;
                }
                .igo_option_upd {
                }
                .igo_option {
                    margin: 0 auto;
                    width: 220px;
                    font-size: 15px;
                    line-height: 35px;
                    font-weight: 600;
                    .live {
                        float: left;
                        text-align: center;
                        width: 110px;
                        height: 35px;
                        background: #F5B237;
                        border-radius: 24px;
                        cursor: pointer;
                    }
                    .finished {
                        text-align: right;
                        width: 220px;
                        height: 35px;
                        background: #585757;
                        border-radius: 24px;
                        cursor: pointer;
                        .text_finished {
                            width: 110px;
                            text-align: center;
                            margin-left: 110px;
                        }
                    }
                    .upd_live {
                        text-align: left;
                        width: 220px;
                        height: 35px;
                        background: #585757;
                        border-radius: 24px;
                        cursor: pointer;
                        .text_live {
                            width: 110px;
                            text-align: center;
                        }
                    }
                    .upd_finished {
                        float: right;
                        margin-top: -35px;
                        text-align: center;
                        width: 110px;
                        height: 35px;
                        background: #F5B237;
                        border-radius: 24px;
                        cursor: pointer;
                    }
                }
                .igo_search {
                    @media screen and (max-width: 768px) {
                        display: none;
                    }

                    z-index: -1;
                    position: absolute;

                    margin-top: -35px;
                    float: right;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-items: center;
                    margin-right: 15px;
                    width: 216px;
                    height: 35px;
                    background: #585757;
                    opacity: 0.37;
                    border-radius: 24px;
                    img {
                        width: 16px;
                        margin-left: 12px;
                        cursor: pointer;
                    }
                    .search_input {
                        width: 180px;
                        height: 30px;
                        border-style:none;
                        background:none;
                    }
                }
            }
            .fundraising_pool {
                @media screen and (max-width: 768px) {
                    zoom: 1.8;
                }

                display: flex;
                justify-content: space-evenly;
                flex-direction: row;
                flex-wrap: wrap;
                .pool_info {
                    width: 370px;
                    height: 420px;
                    background: #3A3A3A;
                    border-radius: 12px;
                    margin: 30px 20px;
                    .top_label {
                        width: 80px;
                        height: 18px;
                        background: #F5B336;
                        border-radius: 11px;
                        text-align: center;
                        font-size: 12px;
                        line-height: 18px;
                        font-weight: 600;
                        margin-left: calc(100% - 80px);
                    }
                    .pool_top {
                        height: 90px;
                        margin-top: -5px;
                        display: flex;
                        justify-content: space-evenly;
                        align-items: center;
                        border-bottom: 2px solid #171621 !important;
                        .tu_biao {
                            img {
                                background-color: goldenrod;
                                border-radius: 50%;
                                aspect-ratio: 1/1;
                                width: 65px;
                            }
                        }
                        .pool_link {
                            .link_text {
                                font-size: 16px;
                                font-family: Poppins;
                                font-weight: 500;
                                color: #FFFFFF;
                                line-height: 26px;
                                /*text-align: center;*/
                            }
                            .to_link {
                                a {
                                    img {
                                        width: 15px;
                                        margin: 5px 5px;
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
                        .pool_name {
                            text-align: center;
                            line-height: 32px;
                            color: #0B0F1D;
                            font-weight: 600;
                            width: 135px;
                            height: 32px;
                            background: #F5B237;
                            border-radius: 17px;
                            cursor: pointer;
                        }
                    }
                    .pool_data {
                        position: relative;
                        img {
                            width: 180px;
                            position: absolute;
                            right: 0;
                            top: 25px;
                        }
                        .pool_remarks {
                            color: #f3a602;
                            font-weight: 600;
                            text-align: center;
                            margin-top: 25px;
                            margin-bottom: -30px;
                        }
                        .data_info {
                            display: flex;
                            justify-content: space-around;
                            margin-top: 30px;
                            .left_data {
                                font-size: 15px;
                                font-family: Asap;
                                font-weight: bold;
                                color: #FFFFFF;
                                line-height: 22px;
                                div{
                                    margin: 30px 0;
                                }
                            }
                            .right_data {
                                font-size: 15px;
                                font-family: Asap;
                                font-weight: bold;
                                color: #FFFFFF;
                                line-height: 22px;
                                div{
                                    margin: 30px 0;
                                }
                            }
                            .many {
                                div {
                                    margin: 20px 0 !important;
                                }
                            }
                        }
                        .pool_button {
                            position: absolute;
                            top: 210px;
                            left: 125px;
                            width: 120px;
                            height: 40px;
                            background: #F5B237;
                            box-shadow: 1px 3px 0px 0px #AB5500;
                            border-radius: 23px;
                            text-align: center;
                            line-height: 40px;
                            font-size: 16px;
                            font-family: Poppins;
                            font-weight: bold;
                            color: #FFFFFF;
                            cursor: pointer;
                        }
                    }
                }
            }
            .node_info {
                @media screen and (max-width: 768px) {
                    height: 1010px;
                }

                width: 96%;
                max-width: 940px;
                height: 1000px;
                background: #E19B00;
                border-radius: 12px;
                margin: 30px auto;
                position: relative;
                .img1 {
                    background-image: url("../../../assets/images/pc/1.png");
                    background-size: 100% 100%;
                    width: 50px;
                    height: 50px;
                    text-align: center;
                    line-height: 50px;
                    position: absolute;
                    top: 150px;
                    left: 30px;
                    cursor: pointer;
                }
                .img2 {
                    background-image: url("../../../assets/images/pc/2.png");
                    background-size: 100% 100%;
                    width: 50px;
                    height: 50px;
                    text-align: center;
                    line-height: 50px;
                    position: absolute;
                    top: 350px;
                    left: 30px;
                    z-index: 10;
                    cursor: pointer;
                }
                .img3 {
                    background-image: url("../../../assets/images/pc/3.png");
                    background-size: 100% 100%;
                    width: 50px;
                    height: 50px;
                    text-align: center;
                    line-height: 50px;
                    position: absolute;
                    top: 550px;
                    left: 30px;
                    z-index: 10;
                    cursor: pointer;
                }
                .img4 {
                    background-image: url("../../../assets/images/pc/4.png");
                    background-size: 100% 100%;
                    width: 50px;
                    height: 50px;
                    text-align: center;
                    line-height: 50px;
                    position: absolute;
                    top: 750px;
                    left: 30px;
                    cursor: pointer;
                }
                .juxing {
                    background-image: url("../../../assets/images/pc/juxing.png");
                    background-size: 100% 100%;
                    position: absolute;
                    top: 200px;
                    left: 54px;
                    width: 2px;
                    height: 550px;
                    z-index: 1;
                }
                .div1 {
                    text-align: center;
                    font-size: 36px;
                    font-family: Poppins;
                    font-weight: bold;
                    color: #FFFFFF;
                    height: 100px;
                    line-height: 100px;
                }
                .div2 {
                    width: 650px;
                    min-width: 80%;
                    height: 150px;
                    background: #3A3A3A;
                    opacity: 0.49;
                    border-radius: 12px;
                    margin-left: 100px;
                    .div_img {
                        font-size: 19px;
                        font-family: Poppins;
                        font-weight: bold;
                        color: #FFFFFF;
                        background-image: url("../../../assets/images/pc/anniu2.png");
                        background-size: 100% 100%;
                        width: 170px;
                        height: 43px;
                        text-align: center;
                        line-height: 40px;
                        cursor: pointer;
                        margin-top: -35px;
                        margin-left: calc(100% - 200px);
                    }
                }
                .div3 {
                    width: 650px;
                    min-width: 80%;
                    height: 150px;
                    background: #3A3A3A;
                    opacity: 0.76;
                    border-radius: 12px;
                    position: absolute;
                    left: 100px;
                    top: 300px;
                    .div_img {
                        background-image: url("../../../assets/images/pc/xingqiu3.png");
                        background-size: 100% 100%;
                        width: 218px;
                        height: 150px;
                        margin-top: -130px;
                        margin-left: 300px;
                    }
                }
                .div4 {
                    width: 650px;
                    min-width: 80%;
                    height: 150px;
                    background: #3A3A3A;
                    opacity: 0.5;
                    border-radius: 12px;
                    position: absolute;
                    left: 100px;
                    top: 500px;
                    .div_img {
                        background-image: url("../../../assets/images/pc/xingqiu2.png");
                        background-size: 100% 100%;
                        width: 168px;
                        height: 120px;
                        margin-top: -80px;
                        margin-left: 450px;
                    }
                }
                .div5 {
                    width: 650px;
                    min-width: 80%;
                    height: 150px;
                    background: #3A3A3A;
                    opacity: 0.75;
                    border-radius: 12px;
                    position: absolute;
                    left: 100px;
                    top: 700px;
                    .div_img {
                        background-image: url("../../../assets/images/pc/xingqiu1.png");
                        background-size: 100% 100%;
                        width: 218px;
                        height: 150px;
                        margin-top: -130px;
                        margin-left: 380px;
                    }
                }
                .div6 {
                    margin-top: 630px;
                    .tisp {
                        width: 650px;
                        margin: 0 auto 10px;
                        /*text-align: center;*/
                        font-size: 29px;
                        font-family: Poppins;
                        font-weight: 500;
                        color: #FFFFFF;
                    }
                    .tisp_info {
                        @media screen and (max-width: 768px) {
                            font-size: 18px;
                        }
                        width: 650px;
                        margin: 0 auto;
                        font-size: 16px;
                        font-family: Poppins;
                        font-weight: 500;
                        color: #FFFFFF;
                    }
                }
                .div_title {
                    @media screen and (max-width: 768px) {
                        font-size: 24px;
                    }

                    position: relative;
                    top: 15px;
                    left: 30px;
                    font-size: 22px;
                    font-family: Poppins;
                    font-weight: bold;
                    color: #FFFFFF;
                }
                .div_info {
                    @media screen and (max-width: 768px) {
                        font-size: 18px;
                    }

                    font-size: 15px;
                    font-family: Poppins;
                    font-weight: 500;
                    color: #FFFFFF;
                    margin: 30px 30px;
                }
            }
        }
    }
</style>